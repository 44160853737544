import React, { Component } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import GlobalStyles from '../components/globalStyles'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from '../components/Header'
import BlogSection from '../components/BlogSection'
import ContactSection from '../components/ContactSection'
import Footer from '../components/footer'
import heroImage from '../images/hero.png'
import {initMap} from '../utils/utils'
import ClinicDoctorsSection from "../components/ClinicDoctorsSection"
import TeamAndPartnerSection from "../components/TeamAndPartnerSection"
import NewestWikiArticlesSection from "../components/NewestWikiArticlesSection"
import path1 from '../images/path-01.svg'
import path2 from '../images/path-02.svg'
import path3 from '../images/path-03.svg'
import path4 from '../images/path-04.svg'
import icon1 from '../images/icon1.svg'
import icon3 from '../images/icon3.svg'
import icon4 from '../images/icon4.svg'
import DoctorDiseasesSection from "../components/DoctorDiseasesSection";
import { SectionHeader } from '../components/HelperComponents'


const DiseaseContentWrapper = styled.div`
  p, ul, li {
    // color: #3c3c3c;
  }

  ul {
    padding: 8px 16px;
  }
  
  li {
    font-size: 16px;
  }
`;

const TitleSection = styled.div`
  margin: 160px 0 120px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;

  @media(max-width: 1184px) {
    align-items: flex-start;
    text-align: left;
    margin: 80px auto 24px;
    width: 95%;
  }
`;

const TagSection = styled.div`
  display: flex;
  align-items: center;
`;

const Tag = styled.h1`
  font-size: 32px;
  line-height: 78px;
  margin-top: 1px;

  @media(max-width: 1184px) {
    line-height: 45px;
  }
`;

const LineLeft = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-right: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const LineRight = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-left: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const H1 = styled.h1`
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 20px;
  
  @media(max-width: 1184px) {
    margin: 80px auto 24px;
    width: 95%;
  }
`;

const Features = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 48px;
  margin-top: 64px;
  
  @media(max-width: 1184px) {
    grid-template-columns: 1fr;
  }
`

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    width: 60px;
  
    @media(max-width: 1184px) {
      width: 50px;
    }
  }
  .header {
    color: #fff;
    margin-top: 12px;
    font-weight: 600;
    line-height: 1.7;
  }
  .text {
    margin-top: 16px;
    line-height: 1.7;
  }
`



const diseaseDescriptions = [
  {
    id: 0,
    title: 'Halsschlagader',
    description: 'Die Carotis Ist das wichtigste Blutgefäss für das Hirn. Bei zunehmender Verengung der Halsschlagader steigt das Risiko für einen Schlaganfall.',
    diseaseSlug: 'halsschlagader-carotis',
    color: '#ff413d',
    positionTop: '13%',
    positionLeft: '51.5%',
  },
  {
    id: 1,
    title: 'TOS',
    description: 'Das Thoracic-Outlet-Syndrom ist ein Kompressionssyndrom im Bereich Schultergürtel/Hals. Dabei werden je nach Fall Nerven, die Arterie (Schlagader) oder Vene zwischen dem Schlüsselbein und der ersten Rippe eingeengt.',
    diseaseSlug: 'thoratic-outlet-syndrom-tos',
    color: '#ff413d',
    positionTop: '18.5%',
    positionLeft: '61%',
  },
  {
    id: 2,
    title: 'Hauptschlagader',
    description: function() {return (<DiseaseContentWrapper>
      <p>Man unterscheidet Brust- und Bauchschlagader (thorakale und abdominale Aorta). Hauptsächliche Erkrankungen sind das Aorten-Aneurysma (Erweiterung), Dissektion (Aufspalten der Gefässwand) und die arteriosklerotische Einengung bis hin zum Verschluss (Leriche-Syndrom bei Verschluss der
distalen Aorta). Drei Abschnitte der Aorta stellen besonders komplexe Strukturen dar.</p>
      <ul>
        <li>1. Die Aortenwurzel</li>
        <li>2. Der Aortenbogen</li>
        <li>3. Die viszerale Aorta</li>
      </ul>
      <p>Die Aortenbifurkation und die Beckengefässen können sich erweitern und die Wandschichten können plötzlich einreissen. Dazu können diese Abschnitte durch die Verschlusskrankheit betroffen werden.</p>
    </DiseaseContentWrapper>)}(),
    diseaseSlug: 'hauptschlagader-aorta',
    color: '#ff413d',
    positionTop: '30.7%',
    positionLeft: '46.8%',
  },
  {
    id: 3,
    title: 'Beckenschlagader',
    description: 'Hier haben wir es vorwiegend mit arteriosklerotischen Veränderungen und als Folge davon kann es zur Einschränkung der Gehstrecke (Schaufensterkrankheit) zu tun.',
    diseaseSlug: 'beckenschlagader',
    color: '#ff413d',
    positionTop: '45%',
    positionLeft: '60%',
  },
  {
    id: 4,
    title: 'Beinschlagader',
    description: 'Die Arterien der Beine haben oft sehr ausgeprägte arteriosklerotische Veränderungen, welche zu Durchblutungsstörungen der Beine führen. Dies führt zur Einschränkung der Gehstrecke (Schaufensterkrankheit) bis hin zum Absterben der Gliedmasse (Raucherbein).',
    diseaseSlug: 'beinschlagader',
    color: '#ff413d',
    positionTop: '57.6%',
    positionLeft: '64%',
  },
  {
    id: 5,
    title: 'Krampfadern',
    description: 'Wenn die oberflächlichen Venen an den Beinen undichte Venenklappen haben, fliesst das Blut nicht mehr nur zum Herzen hin, sondern wieder ins Bein zurück. Dann weiten sich die Venen immer mehr aus. Symptome sind: Wadenkrämpfe, Juckreiz, schwere und müde Beine, oberflächliche Venenentzündungen (Thrombophlebitis), Thrombosen und offene Beine (Ulcus cruris).',
    diseaseSlug: 'krampfadern-varikose',
    color: '#203bbc',
    positionTop: '76.6%',
    positionLeft: '70%',
  }
];

class IndexPage extends Component {
  componentDidMount() {
    window.google && initMap();
  }

  render() {
    return (
      <>
        <Helmet>
          <meta property="og:title" content="Aorten GefässZentrum Hirslanden Zürich" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="http://gefaess.ch/" />
          <meta property="og:image" content={heroImage} />
          <meta property="og:keywords" content="Gefässchirurgie, Aortenchirurgie, Gefässmedizin, Gefässspezialist Zürich" />
        </Helmet>
        <GlobalStyles/>
        <Header />

        <Layout
          images={[
            {
              path: path1,
              styles: {top: '200px', right: '100px', opacity: '0.7'},
            },
            {
              path: path2,
              styles: {top: '35%', left: '20px', opacity: '0.5'},
            },
            {
              path: path3,
              styles: {top: '52%', left: '20%', opacity: '0.4'},
            },
            {
              path: path4,
              styles: {bottom: '-1000px', left: '0px', opacity: '0.3'},
            }
          ]}
        >
          <SEO
            title="Aorten GefässZentrum Hirslanden Zürich"
            description="Führendes Zentrum im Raum Zürich für Gefässmedizin und Gefässchirurgie in der Hirslanden Klinik im Park Zürich. Unter Leitung von Dr. Michael Szente Varga und Prof. Dr. Mario Lachat." />
          <div id="home" className="scrollSection">
            <TitleSection>
              <TagSection>
                <LineLeft />
                <Tag>Ihr Gefässspezialist in Zürich</Tag>
                <LineRight />
              </TagSection>
              <p>Unter der Leitung von Prof. Dr. med. Mario Lachat und Dr. med. Michael Szente Varga operiert das Aorten- und GefässZentrum als führendes Zentrum für Gefässmedizin im Raum Zürich</p>
              <Features>
                <Feature>
                  <img src={icon1} alt="" />
                  <p className="header">Interdisziplinäres<br/>Kompetenzzentrum</p>
                  <p className="text">Spezialisten aus den Gebieten Kardiologie, Herzchirurgie Angiologie und Wundbehandlung unter einem Dach.</p>
                </Feature>
                <Feature>
                  <img src={icon3} alt="" />
                  <p className="header">Erfahren und<br/>innovativ</p>
                  <p className="text">Das Aorten- und Gefässzentrum vereint Kompetenz in traditionellen und hoch innovativen Verfahren der Chirurgie und beteiligt sich massgeblich an deren Entwicklung.</p>
                </Feature>
                <Feature>
                  <img src={icon4} alt="" />
                  <p className="header">Online für<br/>Sie da</p>
                  <p className="text">Buchen Sie Ihren Termin hier oder machen Sie Gebrauch von unseren Online-Sprechstunde.</p>
                </Feature>
              </Features>
            </TitleSection>
          </div>

          <DoctorDiseasesSection
            name="Dr. Szente Varga"
            diseaseDescriptions={diseaseDescriptions}
            headersColor="#fff"
            textColor="#c8d2ff" />

          <ClinicDoctorsSection />
          <TeamAndPartnerSection />
          <NewestWikiArticlesSection />

          {/* <H1>Neueste Fachartikel</H1> */}
          <SectionHeader title="Neueste Fachartikel" />
          <BlogSection alignLeft limit={3} />

          <ContactSection title="Aorten- und GefässZentrum" />
        </Layout>
        <Footer />
      </>
    );
  }
}

export default IndexPage
