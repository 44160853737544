import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import axios from "axios";
import {URL} from "../utils/utils";
import { SectionHeader } from './HelperComponents';


const NewestWikiArticlesWrapper = styled.div`
  font-family: 'Poppins', sans-serif;
  margin: 80px 0;
  
  @media(max-width: 1184px) {
    display: block;
    margin: 80px auto;
    width: 95%;
  }
`;

const H1 = styled.h1`
 font-size: 32px;
 line-height: 1.2;
  margin-bottom: 30px;
`;


const Article = styled.div`
  background: #203bbc;
  color: #fff;
  border-radius: 5px;
  padding: 25px 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media(max-width: 1184px) {
    padding: 13px 20px;
  }
`;

const ArticleArrow = styled.span`
  font-size: 1.4em;
  color: #94a7ff;
`;


class NewestWikiArticlesSection extends Component {
  state = {
    articles: [],
  }

  componentDidMount() {
    axios.get(`${URL}/api/diseases?order_by=updated_at`)
      .then(response => {
        this.setState({
          articles: response.data
            .slice(0, 2)
        });
      })
      .catch(err => console.log(err));
  }

  render() {
    return (
      <NewestWikiArticlesWrapper>
        {/* <H1>Neueste Wiki-Artikel</H1> */}
        <SectionHeader title="Neueste Wiki-Artikel" noPadding={true} />

        {
          this.state.articles.map((article, index) => (
            <Link key={index} to={`/wiki?article=${article.slug}`}>
              <Article>
                <span>{article.name}</span>
                <ArticleArrow>&gt;</ArticleArrow>
              </Article>
            </Link>
          ))
        }
      </NewestWikiArticlesWrapper>
    )
  }
}

export default NewestWikiArticlesSection;