import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import michaelSzenteVarga006 from '../images/006.jpg'
import lachatMario from '../images/lachat_mario_1.jpg'
import Tag from '../components/tag'
import { SectionHeader } from './HelperComponents'


const H1 = styled.h1`
  font-size: 32px;
  margin-bottom: 30px;

  @media(max-width: 1184px) {
    display: block;
    margin: 0 auto;
    width: 95%;
  }
`;

const DoctorsWrapper = styled.div`
  font-family: 'Poppins', sans-serif;
  margin-bottom: 80px;
  display: flex;
  color: #fff;
  
  @media(max-width: 1184px) {
    display: block;
    margin: 24px auto;
    width: 95%;
  }
`;

const DoctorBox = styled.div`
  flex: 1;
  
  &:nth-of-type(1) {
    margin-right: 50px; 
  }
  
  &:nth-of-type(2) {
    margin-left: 50px; 
  }
  
  @media(max-width: 1184px) {
    margin-bottom: 50px;
    
    &:nth-of-type(2) {
      margin-left: 0; 
    }
  }
`;

const DoctorImageWrapper = styled.div`
  margin-bottom: 30px;
  height: 230px;
  width: 180px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 25px 5px 5px 5px;
`;

const DoctorImage = styled.img`
  margin-bottom: 20px;
  height: 100%;
`;

const DoctorName = styled.h2`
   font-size: 28px;
   line-height: 1.2;
   margin-bottom: 20px;
`;

const DoctorButtonsWrapper = styled.div`
  margin-top: 40px;
  display: flex;

  flex-wrap: wrap;
`;

const DoctorButton = styled.div`
  margin-right: 20px;
  
  a {
    background: ${props => props.red ? '#ff413d' : '#fff'};
    color: ${props => props.red ? '#fff' : '#12237f'};
    padding: 13px 20px;
    border-radius: 5px;
    
    @media(max-width: 1184px) {
      font-size: 12px;
      padding: 7px 12px;

      display: inline-block;
      margin-bottom: 10px;
    }
  }
`;


class ClinicDoctorsSection extends Component {

  render() {
    return (
      <>
      {/* <Tag tag="Das Zentrum" />
      <H1>Leitung</H1> */}
      <SectionHeader title="Leitung" />

      <DoctorsWrapper>
        <DoctorBox>
          <Link to='/dr-varga'>
            <DoctorImageWrapper>
              <DoctorImage src={michaelSzenteVarga006} alt="dr-varga" />
            </DoctorImageWrapper>
          </Link>
          <Link to='/dr-varga'>
            <DoctorName>Dr. med. Michael Szente Varga</DoctorName>
          </Link>
          <p>
            Facharzt für Chirurgie und Gefässchirurgie.
            <br/>
            Sprechstunden nach Vereinbarung
          </p>
          <br/>
          <p>
            Ein Schwerpunkt der Tätigkeit von Dr. med. Michael Szente Varga liegt in der Behandlung der erweiterten Brust- und Bauchschlagader (Aneurysma) mittels so genannter Endovaskulärer Verfahren.
          </p>
          <DoctorButtonsWrapper>
            <DoctorButton>
              <Link to='/dr-varga'>
                Mehr Erfahren
              </Link>
            </DoctorButton>
            <DoctorButton red>
              <Link to='/dr-varga#kontakt'>
                Termin vereinbaren
              </Link>
            </DoctorButton>
          </DoctorButtonsWrapper>
        </DoctorBox>
        <DoctorBox>
          <Link to='/dr-lachat'>
            <DoctorImageWrapper>
              <DoctorImage src={lachatMario} alt="dr-lachat" />
            </DoctorImageWrapper>
          </Link>
          <Link to='/dr-lachat'>
            <DoctorName>Prof. Dr. med. Mario Louis Lachat</DoctorName>
          </Link>
          <p>
            Facharzt für Herz- und Gefässchirurgie.
            <br/>
            Sprechstunden nach Vereinbarung
          </p>
          <br/>
          <p>
            Prof. Dr. med. Mario Lachat verfügt über langjährige Erfahrung in der klassischen und endovaskulären Behandlung von Gefässkrankheiten.
          </p>
          <DoctorButtonsWrapper>
            <DoctorButton>
              <Link to='/dr-lachat'>
                Mehr Erfahren
              </Link>
            </DoctorButton>
            <DoctorButton red>
              <Link to='/dr-lachat#kontakt'>
                Termin vereinbaren
              </Link>
            </DoctorButton>
          </DoctorButtonsWrapper>
        </DoctorBox>
      </DoctorsWrapper>
    </>
    )
  }
}

export default ClinicDoctorsSection;