import React, { Component } from 'react';
import styled from 'styled-components';
import drLybuaChaykowska from '../images/dr_med_lybua_chaykowska.png'
import nicoleBernet from '../images/nicole-bernet.jpg'
import maryCervasio from '../images/mary-cervasio.png'
import slavicaMarkovicPticek from '../images/Slavica Markovic-Pticek.png'
import beatriceAmannVesti from '../images/Beatrice Amann-Vesti.png'
import reneGabrieli from '../images/Rene_Gabrieli_Portrait.jpg'
import gillesSauvant from '../images/gilles_sauvant.jpg'
import Modal from "./modal";
import medkitIcon from '../images/medkit.svg'
import Tag from '../components/tag'
import { SectionHeader } from './HelperComponents';


const TeamAndPartnerWrapper = styled.div`
  font-family: 'Poppins', sans-serif;
  margin: 80px 0;
  
  @media(max-width: 1184px) {
    display: block;
    margin: 80px auto;
    width: 95%;
  }
`;

const H1 = styled.h1`
  font-size: 32px;
  margin-bottom: 30px;
`;

const PeopleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px 100px;
  
  @media(max-width: 1184px) {
  grid-template-columns: 1fr;
  }
`;

const Person = styled.div`
  color: #fff;
  margin-bottom: 40px;
`;

const ImageWrapper = styled.div`
  margin-bottom: 30px;
  height: 230px;
  width: 180px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 5px;
`;

const Image = styled.img`
  height: 100%;
`;

const PersonName = styled.h2`
 font-size: 28px;
 line-height: 1.2;
 margin-bottom: 5px;
`;

const PersonTag = styled.p`
  color: #dee4ff;
  margin-bottom: 5px;
  font-size: 18px;
`;

const CVButton = styled.div`
  cursor: pointer;
  margin: 20px 0;
  display: inline-block;
  margin-right: 20px;
  padding: 12px 20px;
  border-radius: 5px;
  border: 1px solid #fff;
  
  @media(max-width: 1184px) {
    font-size: 0.85em;
    padding: 7px 12px;
  }
`;

const Year = styled.p`
  color: #fff;
`;


const renderDrLybuaChaykowskaCvContent = () => (<div>
  <br/>
  <p style={{textAlign: 'left'}}>Dr. med. Lyubov Chaykovska erhielt ihre Approbation als Ärztin an der Nationalen Medizinischen Danylo-Halytskyj-Universität Lemberg (Ukraine) und an der Medizinischen Fakultät der Universität Zürich (Schweiz). Sie promovierte mit summa cum laude an der Charité - Universitätsmedizin Berlin (Deutschland). Dr. Chaykovska absolvierte im Jahr 2017 die Europäische Facharztprüfung und erhielt das Diplom Fellow des European Board of Vascular Surgery (FEBVS). Sie ist Autorin von 28 wissenschaftlichen Publikationen in internationalen medizinischen Fachzeitschriften.</p>
  <br/>
  <ul>
    <li>
      <Year>11.2017 - aktuell</Year>
      <p>Oberärztin für Gefässchirurgie, Klinik Hirslanden und Klinik Im Park, Zürich, Schweiz</p>
    </li>
    <li>
      <Year>07.2011 – aktuell</Year>
      <p>Gefäßchirurgin und Forscherin, Klinik für Herz- und Gefäßchirurgie, Universitätsspital Zürich, Schweiz </p>
    </li>
    <li>
      <Year>10.2010 – 06.2011</Year>
      <p>Laborleiter, Abteilung für kardiovaskuläre und metabolische Forschung, Hofmann La Roche, Basel, Schweiz</p>
    </li>
    <li>
      <Year>02.2007 - 09.2010</Year>
      <p>Experimenteller Mikrochirurgin und Forscherin, Zentrum für Herz-Kreislauf-Forschung (Center for Cardiovascular Research, CCR), Charité - Universitätsmedizin Berlin, Deutschland</p>
    </li>
    <li>
      <Year>08.2002 - 01.2007</Year>
      <p>Assistenzärztin, Klinik für Allgemeine- und Gefäßchirurgie, Nationale Medizinische Danylo-Halytskyj-Universität Lemberg, Ukraine</p>
    </li>
  </ul>
  <h5>VISITATIONEN UND FELLOWSHIPS</h5>
  <ul>
    <li>
      <Year>März 2013</Year>
      <p>Abteilung für Gefässchirurgie, Karolinska-Universitätskrankenhaus, Stockholm, Sweden (Prof. Linus Blohmé)</p>
    </li>
    <li>
      <Year>April - März 2014</Year>
      <p>Kawaminami Hospital, Tokio, Japan (Dr. Misato Kobayashi)</p>
    </li>
    <li>
      <Year>September - Oktober 2015</Year>
      <p>Gefässchirurgie, Stanford University Medical Center, Stanford, USA (Professor Jason T. Lee)</p>
    </li>
    <li>
      <Year>Juli - August 2015</Year>
      <p>Klinik für Thorax- und Gefässchirurgie, Universitätsklinikum Gent, Gent, Belgien (Prof. Frank E Vermassen).</p>
    </li>
    <li>
      <Year>Februar - März 2015</Year>
      <p>NYU Langones Abteilung für Offene- und Endovaskuläre Gefässchirurgie, New York, USA (Prof. Mark A. Adelman)</p>
    </li>
  </ul>
</div>);


class TeamAndPartnerSection extends Component {
  state = {
    displayCV: false,
  }

  hideCv = () => {
    this.setState({
      displayCV: false
    });
  }

  openCV = () => {
    this.setState({
      displayCV: true
    });
  }

  render() {
    return (
      <TeamAndPartnerWrapper>

        <Modal show={this.state.displayCV} clicked={this.hideCv}>
          <h2>CV Dr. med. Lyubov Chaykovska</h2>
          {renderDrLybuaChaykowskaCvContent()}
        </Modal>

        {/* <Tag tag="Das Zentrum" />
        <H1>Team</H1> */}
        <SectionHeader title="Team" />

        <PeopleWrapper>
          <Person>
            <ImageWrapper onClick={this.openCV}>
              <Image src={drLybuaChaykowska} alt="partner" />
            </ImageWrapper>
            <PersonName onClick={this.openCV}>Dr. med. Lyubov Chaykovska</PersonName>
            <PersonTag >Oberärztin</PersonTag>
            <CVButton onClick={this.openCV}>Experience & Bio</CVButton>
          </Person>

          <Person>
            <ImageWrapper>
              <Image src={maryCervasio} alt="partner" />
            </ImageWrapper>
            <PersonName>Mary Cervasio</PersonName>
            <PersonTag>Persönliche Assistentin Dr. med. Michael Szente Varga</PersonTag>
          </Person>

          <Person>
            <ImageWrapper>
              <Image src={nicoleBernet} alt="partner" />
            </ImageWrapper>
            <PersonName>Nicole Bernet</PersonName>
            <PersonTag>Persönliche Assistentin Dr. med. Michael Szente Varga</PersonTag>
          </Person>

          <Person>
            <ImageWrapper>
              <Image src={reneGabrieli} alt="partner" />
            </ImageWrapper>
            <PersonName>Rene Gabrieli</PersonName>
            <PersonTag>Persönlicher Assistent Prof. Dr. med. Mario Louis Lachat</PersonTag>
          </Person>
        </PeopleWrapper>

        {/* <Tag tag="Das Zentrum" />
        <H1>Partner</H1> */}
        <SectionHeader title="Partner" />

        <PeopleWrapper>
          <Person>
            <a href="https://www.hirslanden.ch/de/corporate/aerzte/2/prof-dr-med-beatrice-amann-vesti.html" target="_blank" rel="noopener noreferrer">
              <ImageWrapper>
                <Image src={beatriceAmannVesti} alt="partner" />
              </ImageWrapper>
            </a>
            <a href="https://www.hirslanden.ch/de/corporate/aerzte/2/prof-dr-med-beatrice-amann-vesti.html" target="_blank" rel="noopener noreferrer">
              <PersonName>Prof. Dr. med. Beatrice Amann-Vesti</PersonName>
            </a>
            <PersonTag>Fachärztin für Angiologie & Allg. Innere Medizin</PersonTag>
            <p>Klinik Hirslanden</p>
            <p>Witellikerstrasse 40</p>
            <p>8032 Zürich</p>
            <p><a href="https://www.hirslanden.ch/de/corporate/aerzte/2/prof-dr-med-beatrice-amann-vesti.html" target="_blank" rel="noopener noreferrer">
              <img src={medkitIcon} alt="medkit" />
            </a></p>
          </Person>

          <Person>
            <ImageWrapper>
              <Image src={slavicaMarkovicPticek} alt="partner" />
            </ImageWrapper>
            <PersonName>Slavica Markovic-Pticek</PersonName>
            <PersonTag>Wundexpertin</PersonTag>
            <p>Ambulante Wundbehandlung</p>
            <p>Klinik Hirslanden</p>
            <p>Witellikerstrasse 40</p>
            <p>8032 Zürich</p>
            <p><a href="http://www.ambulantewundbehandlung.ch" target="_blank" rel="noopener noreferrer">
              <img src={medkitIcon} alt="medkit" />
            </a></p>
          </Person>

        </PeopleWrapper>

      </TeamAndPartnerWrapper>
    )
  }
}

export default TeamAndPartnerSection;
